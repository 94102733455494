import React, { useContext, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  Row,
  FormFeedback,
  Alert,
} from 'reactstrap';
import { UserContext } from 'components/AuthProvider';
import ProjectsTable from '../dashboard/ProjectsTable';

const PM_API = process.env.REACT_APP_PM_API;

const General = () => {
  const { getAccessToken } = useContext(UserContext);
  const [sucessMessage, setSuccessMessage] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [search, setSearch] = useState();
  const [projects, setProjects] = useState({});
  const [liveProjectsTable, setLiveProjectsTable] = useState(true);

  const toggleLiveProjects = () => {
    setLiveProjectsTable(!liveProjectsTable);
  };

  const requestOptions = (token, data) => ({
    credentials: 'include',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const saveData = (data) => {
    setSearching(true);
    getAccessToken().then((token) =>
      fetch(
        `${PM_API}/api/v1/project/history/find`,
        requestOptions(token, data)
      )
        .then((res) => res.json())
        .then((res) => setProjects(res))
        .catch((error) => console.log('Error:', error))
        .finally(() => {
          setSearching(false);
        })
    );
  };

  const save = () => {
    saveData({ jobNumber: search });
  };

  return (
    <div>
      <div className="content">
        <Row>
          <Col xs={12} md={12}>
            <div className="page-title">
              <div className="float-left">
                <h1 className="title">Project History</h1>
              </div>
              <div className="float-right">
                <span
                  className="text-muted"
                  style={{
                    fontSize: '15px',
                    padding: '0 15px 0 0',
                    lineHeight: '77px',
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div className="col-12 col-lg-12 col-xl-12 col-md-12 col-sm-12">
          <section className="box">
            <header className="panel_header">
              <h2 className="title float-left">Job Number</h2>
              <button type="button" className="close">
                <span>×</span>
              </button>
            </header>
            <div className="content-body">
              <Form>
                <FormGroup>
                  <Input
                    type="text"
                    name="jobNumber"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                  <FormFeedback>Please select the file first.</FormFeedback>
                </FormGroup>
                <Alert color="success" hidden={!sucessMessage}>
                  Success: upload complete!
                </Alert>
                <Button size="sm" color="success" onClick={save}>
                  Search
                </Button>
              </Form>
            </div>
          </section>
        </div>

        <ProjectsTable
          header="Live Projects"
          tData={projects}
          toggle={toggleLiveProjects}
        />
      </div>
    </div>
  );
};

export default General;
