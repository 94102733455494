import React, { useContext, useState } from 'react';
import { UserContext } from 'components/AuthProvider';
import WidgetBlock from '../../general/Widgets/WidgetBlock';
import ProjectsTable from './ProjectsTable';
import Can from '../../../components/Can';

const PM_API = process.env.REACT_APP_PM_API;

const WidgetSection = ({ isLoading, data, liveProjects }) => {
  const [liveProjectsTable, setLiveProjectsTable] = useState(true);
  const { getAccessToken } = useContext(UserContext);

  const xero = () => {
    const requestOptions = (token) => ({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    getAccessToken().then((token) =>
      fetch(`${PM_API}/api/v1/xero/connect`, requestOptions(token))
        .then((res) => res.json())
        .then((response) => {
          window.location.href = response.consentUrl;
        })
        .catch((error) => console.log('Error:', error))
    );
  };

  const toggleLiveProjects = () => {
    setLiveProjectsTable(!liveProjectsTable);
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={isLoading ? 'd-none' : 'row margin-0'}>
        <WidgetBlock
          title="Current Week"
          subtitle={data.currentWeek}
          icon="i-calendar"
        />

        <WidgetBlock
          title="FY Complete"
          subtitle={
            <div className="progress">
              <span
                className={data.fyCompleted < 50 ? 'text-dark' : 'text-light'}
                style={{
                  position: 'absolute',
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                  marginTop: '-2px',
                }}
              >
                {data.fyCompleted}%
              </span>
              <span
                className="progress-bar progress-bar-primary"
                aria-valuenow="62"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: `${data.fyCompleted}%` }}
              />
            </div>
          }
          icon="i-hourglass"
        />

        <WidgetBlock
          title="Live Projects"
          subtitle={liveProjects.length}
          icon="i-settings"
          clicable
          onClick={() => toggleLiveProjects()}
        />

        {Can('manage:integration') && (
          <WidgetBlock
            title="Xero"
            subtitle="Click to enable"
            icon="i-credit-card"
            clicable
            onClick={() => xero()}
          />
        )}
      </div>
      {liveProjectsTable && (
        <ProjectsTable
          header="Live Projects"
          tData={liveProjects}
          toggle={toggleLiveProjects}
        />
      )}
    </>
  );
};

export default WidgetSection;
