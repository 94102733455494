import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { UserContext } from './AuthProvider';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { user } = useContext(UserContext);

  const render = (props) => (user ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
};

export default withRouter(PrivateRoute);
