import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import AuthProvider from 'components/AuthProvider';
import App from './App';

ReactDOM.render(
  <AuthProvider>
    <IntlProvider locale="en-AU">
      <App />
    </IntlProvider>
  </AuthProvider>,
  document.getElementById('root')
);
