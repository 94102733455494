import { useContext } from 'react';
import rulesList from '../rbac-rules';
import { UserContext } from './AuthProvider';

const Check = (action, data) => {
  const { user } = useContext(UserContext);

  if (user == null) return false;

  const userRoles = user.roles;
  let performAction = action;

  if (!action) {
    performAction = 'read:basic';
  }

  // If the user has no permission set he gets basic access
  if (userRoles.length === 0) {
    userRoles.push('basic');
  }

  const permissions = {
    static: [],
  };

  for (let i = 0; i < userRoles.length; i += 1) {
    const permission = rulesList[userRoles[i]];
    permissions.static = permissions.static.concat(permission.static);
  }

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(performAction)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[performAction];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

export default Check;
