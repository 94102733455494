import React, { useContext } from 'react';
import { Router, Route, Switch } from 'react-router-dom';

import { Spinner } from 'reactstrap';

/* Components */
import indexRoutes from 'routes/index';
import { UserContext } from 'components/AuthProvider';
import PrivateRoute from './components/PrivateRoute';
import history from './utils/history';

/* Styles */
import 'bootstrap/dist/css/bootstrap.css';
import 'assets/scss/zest-admin.scss';
import 'assets/fonts/simple-line-icons.css';

const App = () => {
  const { isLoading } = useContext(UserContext);

  if (isLoading) {
    return (
      <div>
        <Spinner animation="grow" size="sm" role="status" /> Loading ...
      </div>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        {indexRoutes.map((prop, key) => {
          if (prop.public) {
            return (
              <Route path={prop.path} key={key} component={prop.component} />
            );
          }
          return (
            <PrivateRoute
              path={prop.path}
              key={key}
              component={prop.component}
            />
          );
        })}
      </Switch>
    </Router>
  );
};

export default App;
