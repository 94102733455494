import { UserContext } from 'components/AuthProvider';
import { useEffect, useState, useContext } from 'react';

const PM_API = process.env.REACT_APP_PM_API;
const API_VERSION = '/api/v1';

const useFetch = (url) => {
  const { getAccessToken } = useContext(UserContext);
  const [status, setStatus] = useState('IDLE');
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const requestOptions = (token) => ({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  useEffect(() => {
    setStatus('FETCHING');
    setIsLoading(true);
    getAccessToken().then((token) =>
      fetch(`${PM_API + API_VERSION + url}`, requestOptions(token))
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw Error(response.statusText);
        })
        .then((res) => {
          setData(res);
          setStatus('FETCHED');
          setIsLoading(false);
        })
        .catch((error) => {
          setData(error.message);
          setStatus('FETCH_ERROR');
          setIsLoading(false);
        })
    );
  }, [getAccessToken, url]);

  return { status, data, isLoading };
};

export default useFetch;
