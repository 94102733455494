import React, { useContext, useState } from 'react';
import { Button } from 'reactstrap';

import _ from 'lodash';
import { UserContext } from 'components/AuthProvider';

const PM_API = process.env.REACT_APP_PM_API;

const RowEditCell = ({ row }) => {
  const { getAccessToken } = useContext(UserContext);
  const { isEditing } = row.state;
  const [isSaving, setSaving] = useState(false);

  const requestOptions = (token, data) => ({
    credentials: 'include',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const saveData = (data) => {
    setSaving(true);
    getAccessToken().then((token) =>
      fetch(
        `${PM_API}/api/v1/project/update/progress`,
        requestOptions(token, data)
      )
        .then((res) => res.json())
        .catch((error) => console.log('Error:', error))
        .finally(() => {
          setSaving(false);
        })
    );
  };

  const edit = () => {
    const data = _.clone(row.values);
    row.setState({ isEditing: true, isSaving: false, data });
  };

  const save = () => {
    row.setState((currState) => {
      const newState = currState;
      newState.isEditing = false;
      if (_.isEqual(newState.data, row.values) === false) {
        saveData(newState.data);
      }
      return newState;
    });
  };

  if (isSaving) {
    return (
      <Button size="sm" color="info" className="btn-icon">
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </Button>
    );
  }

  if (!isEditing) {
    return (
      <Button size="sm" color="info" className="btn-icon" onClick={edit}>
        <i className="i-pencil icon-xs"> </i>
      </Button>
    );
  }
  return (
    <Button size="sm" color="success" onClick={save}>
      Save
    </Button>
  );
};

export default RowEditCell;
