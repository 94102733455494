import React, { useState, useEffect, forwardRef, useContext } from 'react';
import { Spinner, Table } from 'reactstrap';
import AreaOfWorkRow from 'views/project/location/AreaOfWorkRow';
import { UserContext } from 'components/AuthProvider';
import styles from './LocationTable.module.css';

const PM_API = process.env.REACT_APP_PM_API;

const AreaOfWorkTable = forwardRef(
  ({ companyId, isOpen, toggleChild, row }, ref) => {
    const [isLoading, setLoading] = useState(true);
    const [areasOfWork, setAreasOfWork] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);

    const { getAccessToken } = useContext(UserContext);

    const requestOptions = (token) => ({
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    useEffect(() => {
      const loadAreasOfWork = () => {
        getAccessToken().then((token) =>
          fetch(
            `${PM_API}/api/v1/areas-of-work?company_id=${companyId}`,
            requestOptions(token)
          )
            .then((res) => res.json())
            .then((response) => {
              setAreasOfWork(response);
              setLoading(false);
            })
            .catch((error) => console.log('Error:', error))
        );
      };

      if (isOpen && !dataLoaded) {
        loadAreasOfWork();
        setDataLoaded(true);
      }
    }, [companyId, isOpen, dataLoaded, getAccessToken]);

    return (
      <>
        {isLoading ? (
          <tr>
            <td colSpan="8" align="center">
              <Spinner type="grow" color="primary" />
            </td>
          </tr>
        ) : (
          <tr>
            <td
              colSpan="8"
              className={styles.parentRowPrint}
              style={{ padding: 0 }}
              ref={ref}
            >
              <div className={`${styles.printHeader} d-none d-print-block`}>
                <div
                  style={{ width: '200px', float: 'right', overflow: 'hidden' }}
                >
                  <img
                    alt="Paint Melbourne - logo"
                    src={`${process.env.PUBLIC_URL}/paint_melbourne.png`}
                    style={{ marginTop: '-40px' }}
                  />
                </div>
                <h1>Project Report</h1>
                <p>
                  Job Number: {row.deputy_code}
                  <br />
                  Address: {row.deputy_address}
                  <br />
                  Total time: {row.total_time}
                </p>
                <h2>Stages breakdown</h2>
              </div>
              <Table className={styles.innerTable}>
                <tbody>
                  {areasOfWork.map((area) => (
                    <AreaOfWorkRow
                      key={area.id}
                      area={area}
                      toggleChild={toggleChild}
                    />
                  ))}
                </tbody>
              </Table>
            </td>
          </tr>
        )}
      </>
    );
  }
);

export default AreaOfWorkTable;
