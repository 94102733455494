import React, { useContext, useState } from 'react';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import Can from 'components/Can';
import { UserContext } from 'components/AuthProvider';
import PDFViewer from './PDFViewer';

const PM_API = process.env.REACT_APP_PM_API;

const ProjectExpensesTable = ({ row }) => {
  const { getAccessToken } = useContext(UserContext);
  const [expenses, setExpenses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState();
  const [pdfIndex, setPdfIndex] = useState();
  const [showInvoice, setShowInvoice] = useState(false);

  const requestOptions = (token) => ({
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const toggle = () => {
    setShowInvoice(!showInvoice);
  };

  const loadInvoices = () => {
    setIsLoading(true);
    getAccessToken().then((token) =>
      fetch(
        `${PM_API}/api/v1/project/expenses/${row.jobNumber}`,
        requestOptions(token)
      )
        .then((res) => res.json())
        .then((response) => {
          setExpenses(response);
          setIsLoading(false);
        })
        .catch((error) => console.log('Error:', error))
    );
  };

  const viewInvoice = (index) => {
    const invoice = expenses[index];
    setPdfIndex(index);
    if (!showInvoice) {
      setShowInvoice(true);
    }

    if (invoice.pdfUrl) {
      setPdfUrl(invoice.pdfUrl);
    } else {
      getAccessToken().then((token) =>
        fetch(
          `${PM_API}/api/v1/project/expenses/invoice/${invoice.invoiceID}`,
          requestOptions(token)
        )
          .then((res) => res.json())
          .then((response) => {
            invoice.pdfUrl = response[0];
            setPdfUrl(response[0]);
          })
          .catch((error) => console.log('Error:', error))
      );
    }
  };

  const Currency = ({ value }) => {
    const intl = useIntl();
    return intl.formatNumber(value, {
      style: 'currency',
      currency: 'AUD',
    });
  };

  const InvoicesTable = () => {
    if (isLoading) {
      return 'Loading ...';
    }

    if (!Object.keys(expenses).length) {
      return (
        <div>
          <p>
            <Button onClick={loadInvoices}>Load Invoices</Button>
          </p>
        </div>
      );
    }

    const previous = () => {
      if (pdfIndex > 0) {
        viewInvoice(pdfIndex - 1);
      }
    };

    const next = () => {
      if (pdfIndex < expenses.length - 1) {
        viewInvoice(pdfIndex + 1);
      }
    };

    return (
      <>
        {pdfUrl && (
          <PDFViewer
            pdfUrl={pdfUrl}
            show={showInvoice}
            next={next}
            previous={previous}
            toggle={toggle}
            listSize={expenses.length}
            index={pdfIndex}
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Number</th>
              <th>Supplier</th>
              <th>Total Ex.</th>
              <th>Status</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {expenses.map((invoice, index) => (
              <tr key={invoice.invoiceID}>
                <td>{format(new Date(invoice.date), 'dd/MM/yy')}</td>
                <td>{invoice.ref}</td>
                <td>{invoice.supplier}</td>
                <td>
                  <Currency value={invoice.subTotal} />
                </td>
                <td>{invoice.status}</td>
                <td>
                  <Button
                    size="xs"
                    className="btn-icon"
                    color="info"
                    onClick={() => viewInvoice(index)}
                  >
                    <i
                      style={{ fontSize: '10px' }}
                      className="i-magnifier icon-xs"
                    />
                  </Button>
                </td>
              </tr>
            ))}
            <tr className="font-weight-bold">
              <td colSpan={3} />
              <td style={{ textAlign: 'right' }}>Total:</td>
              <td>
                <Currency value={row.actualMaterialCost} />
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
      </>
    );
  };

  const ExpensesSummary = () => (
    <Table>
      <thead>
        <tr>
          <th>Budget</th>
          <th>Used</th>
          <th>Remaining</th>
          <th>% Used</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Currency value={row.estimatedMaterialCost} />
          </td>
          <td>
            <Currency value={row.actualMaterialCost} />
          </td>
          <td>
            <Currency value={row.materialLeft} />
          </td>
          <td>{row.expBurnDown}%</td>
        </tr>
      </tbody>
    </Table>
  );

  return (
    <>
      <h3>Project Expenses Summary</h3>
      <ExpensesSummary />
      {Can('read:project-invoices') && (
        <>
          <h3>Project Expenses Invoices</h3>
          <InvoicesTable />
        </>
      )}
    </>
  );
};
export default ProjectExpensesTable;
