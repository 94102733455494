import React, { useEffect, useState } from 'react';
import { REACT_APP_LOGIN_URL, API_VERSION, PM_API } from 'utils/constants';
import history from '../utils/history';

export const UserContext = React.createContext(null);

const requestOptions = (token) => ({
  method: 'GET',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const AuthProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const getUser = (accessToken) => {
      if (accessToken) {
        fetch(
          `${`${PM_API + API_VERSION}/user/roles`}`,
          requestOptions(accessToken)
        )
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
            throw Error(response.statusText);
          })
          .then((res) => {
            setUser(res);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    };

    const getToken = () => {
      if (token) return;

      fetch(`${REACT_APP_LOGIN_URL}/me`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          if (response.status === 200) return response.json();
          window.location.href = `${REACT_APP_LOGIN_URL}/login`;
        })
        .then((response) => {
          history.push(window.location.pathname);
          setToken(response.token);
          getUser(response.token);
        });
    };
    getToken();
  }, [token]);

  const logout = () => {
    window.location.href = `${REACT_APP_LOGIN_URL}/logout`;
  };

  const getAccessToken = () => Promise.resolve(token);

  return (
    <UserContext.Provider
      value={{ user, token, logout, isLoading, getAccessToken }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default AuthProvider;
